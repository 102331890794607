import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import "./Auth.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Global from "../../config/Global";

const Login = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit, control, formState, setValue, resetField } =
    useForm({
      mode: "all",
    });

  const [passwordField, setPasswordField] = useState(false);
  const [serverValidation, setServerValidation] = useState({
    status: false,
    message: "",
  });

  const onSubmit = (data: any) => {
    if (data.email) {
      setPasswordField(true);
      setServerValidation({ status: false, message: "" });
    }
    if (data.email && data.password) {
      if (location.pathname === "/signup") {
        signupHandler(data);
      } else {
        loginHandler(data);
      }
    }
  };

  const loginHandler = (data: any) => {
    axios
      .post(`${Global.newBaseUrl}/auth/login`, {
        email: data.email,
        password: data.password,
      })
      .then((res) => {
        if (res.data.statusCode !== 400) {
          if (res.data.data.isProfileCompleted) {
            navigate("/chat");
          } else {
            navigate(`/onboarding/${res.data.data._id}`);
          }

          localStorage.setItem("token", res.data.data.accessToken);
          localStorage.setItem("userId", res.data.data._id);
          localStorage.setItem(
            "user_traits",
            `${res.data.data.firstName} ${res.data.data.lastName}`
          );
        } else {
          setServerValidation({ status: true, message: res.data.data });
          setValue("password", "");
        }
      });
  };

  const signupHandler = (data: any) => {
    axios
      .post(`${Global.newBaseUrl}/auth/signup`, {
        email: data.email,
        password: data.password,
      })
      .then((res) => {
        if (res.data.statusCode !== 400) {
          navigate("/verification");

          localStorage.setItem("email", data.email);
          localStorage.setItem("token", res.data.data.accessToken);
          localStorage.setItem("userId", res.data.data._id);
          localStorage.setItem(
            "user_traits",
            `${res.data.data.firstName} ${res.data.data.lastName}`
          );
        } else {
          setServerValidation({ status: true, message: res.data.data });
          setPasswordField(false);
          setValue("password", "");
        }
      });
  };

  const validationErrorMessage = () => {
    if (serverValidation.status) {
      if (location.pathname === "/signup") {
        return <Alert severity="error">{serverValidation.message}</Alert>;
      } else {
        return <Alert severity="error">{serverValidation.message}</Alert>;
      }
    }
  };

  return (
    <div className="auth">
      <Box className="first-step" sx={{ textAlign: "center", width: "440px" }}>
        <h2 className={location.pathname === "/signup" ? "signup" : ""}>
          {location.pathname === "/login"
            ? "Welcome Back"
            : "Create your account"}
        </h2>
        {location.pathname === "/signup" ? (
          <h4>
            Note that phone verification may be required for signup. Your number
            will only be used to verify your identity for security purposes.
          </h4>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Box sx={{ position: "relative" }}>
                <TextField
                  className="custom"
                  id="email"
                  fullWidth
                  type="email"
                  label="Email Address"
                  error={!!formState.errors.email}
                  helperText={
                    formState.errors?.email ? (
                      <>{formState.errors?.email?.message}</>
                    ) : (
                      ""
                    )
                  }
                  disabled={passwordField}
                  {...field}
                  {...register("email", {
                    onChange: () => {
                      setServerValidation({ status: false, message: "" });
                    },
                    required: "Please Enter Email",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Invalid email address",
                    },
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setPasswordField(false);
                            setValue("password", "");
                            resetField("password");
                          }}
                        >
                          {passwordField ? <EditIcon /> : null}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
          />
          {passwordField ? (
            <Controller
              name={"password"}
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  error={!!formState.errors.password}
                  helperText={
                    formState.errors?.password ? (
                      <>{formState.errors?.password?.message}</>
                    ) : (
                      ""
                    )
                  }
                  {...field}
                  {...register("password", {
                    onChange: () => {
                      setServerValidation({ status: false, message: "" });
                    },
                    required: "Please Enter password",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                />
              )}
            />
          ) : null}
          {validationErrorMessage()}
          <Button
            sx={{
              marginTop: "20px",
              width: "100%",
              padding: "15px",
              fontSize: "18px",
              textTransform: "capitalize",
            }}
            type="submit"
            color="success"
            variant="contained"
          >
            Continue
          </Button>
        </form>

        <p className="para">
          {location.pathname === "/login" ? (
            <>
              `Don't have an account? <Link to="/signup">Sign up</Link>
            </>
          ) : (
            <>
              Already have an account? <Link to="/login">Log in</Link>
            </>
          )}
        </p>
      </Box>
    </div>
  );
};

export default Login;
