import Home from "./container/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/auth/Auth";
import { Verification } from "./components/auth/Verification";
import { Profile } from "./components/profile/Profile";
import Chat from "./components/chat/Chat";
import PrivateRoute from "./PrivateRoute";
import { useEffect } from "react";
import "github-markdown-css";

const App = () => {
  const location = useLocation();
  if (location.pathname !== "/verification") {
    localStorage.removeItem("email");
  }

  useEffect(() => {
    const setVhProperty = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    window.addEventListener("resize", setVhProperty);
    setVhProperty();
    return () => {
      window.removeEventListener("resize", setVhProperty);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="login"
          element={<Login key={window.location.pathname} />}
        />
        <Route path="signup" element={<Login />} />
        <Route path="verification" element={<Verification />} />
        <Route path="onboarding/:id" element={<Profile />} />
        <Route path="chat" element={<PrivateRoute component={Chat} />} />
      </Routes>
    </>
  );
};

export default App;
