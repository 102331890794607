import { Box, Button, Grid } from "@mui/material";
import "./Home.css";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  return (
    <Grid
      className="main-container"
      sx={{ display: "flex", alignItems: "center", height: "100vh" }}
    >
      <div className="left">
        <h2>Best AI in the market</h2>
      </div>
      <div className="right">
        <h2>Get Started</h2>
        <Box
          sx={{
            marginBottom: "auto",
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <Button variant="contained" onClick={() => navigate("/login")}>
            Log in
          </Button>
          <Button variant="contained" onClick={() => navigate("/signup")}>
            Sign up
          </Button>
        </Box>
      </div>
    </Grid>
  );
}

export default Home;
