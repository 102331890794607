import AddIcon from "@mui/icons-material/Add";
import {
  IconButton,
  Button,
  styled,
  Menu,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  createTheme,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import "./Chat.css";
import axios from "axios";
import Global from "../../config/Global";
import { MouseEvent, useEffect, useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import ChatClient, { SendQueryParams } from "../../helpers/Chat.client";
import { Option as BaseOption, Select } from "@mui/base";
import InitialsAvatar from "react-initials-avatar";
import isMobile from "../../helpers/utils";
import TextareaAutosize from "react-textarea-autosize";
import { MoreVertOutlined, SmartToy, ToggleOn } from "@mui/icons-material";
import { Threads } from "./threads";
import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";

const Option = styled(BaseOption)(
  ({ theme }) => (
    console.log(theme.palette),
    {
      "&.MuiOption-root": {
        listStyle: "none",
        padding: "8px",
        color: theme.palette.mode === "light" ? "#000" : "#fff",
        fontSize: "14px",
        cursor: "pointer",
        "&:last-of-type": {
          borderBottom: "none",
        },
        "&:hover": {
          backgroundColor: "#202123",
          color: "#fff",
        },
        "&.Mui-selected": {
          backgroundColor: "#40414f",
        },

        "&.MuiOption-highlighted": {
          fontWeight: 700,
          backgroundColor: "#202123",
          color: "#fff",
        },
      },
    }
  )
);

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiList-root": {
    background: theme.palette.mode === "light" ? "#fff" : "#000",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    display: "flex",
    justifyContent: "space-between",
    margin: 4,
    borderRadius: "5px",
    color: theme.palette.mode === "light" ? "#000" : "#fff",
    "&:hover": {
      background: theme.palette.mode === "light" ? "#efefef" : "#1f1e1e",
    },
  },
}));

interface Message {
  type: string;
  text: string;
}

const Chat = (props: any) => {
  const scrollRef = useRef<any>();
  const token = localStorage.getItem("token");
  const [threadList, setThreadList] = useState<any>([]);
  const [selectedThread, setSelectedThread] = useState<any>();
  const [newThreadId, setNewThreadId] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectOption, setSelectOption] = useState<string>("ValGPT");
  const [newChat, setNewChat] = useState<boolean>(true);
  const [toggle, setToggle] = useState(isMobile ? false : true);
  const [loading, setLoading] = useState<boolean>(false);
  const [titleChange, setTitleChange] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [themeToggle, setThemeToggle] = useState(false);
  const [mode, setMode] = useState<"light" | "dark">("light");
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (token) {
      getThreadList();
    }
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleChange = () => {
      setMode(mediaQuery.matches ? "dark" : "light");
    };

    mediaQuery.addEventListener("change", handleChange);

    handleChange();

    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  const theme = createTheme({
    palette: {
      mode: mode,
    },
  } as ThemeOptions);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getThreadList = () => {
    axios
      .get(`${Global.newBaseUrl}/thread/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const currentDate = new Date();

        // Subtract one day (24 hours) from the current date to get yesterday's date
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        // Filter chats created on yesterday
        const yesterdayChats = res.data.data.filter((chat: any) => {
          const chatDate = new Date(chat.updatedAt);
          return chatDate >= yesterday && chatDate < currentDate;
        });

        const sortedMessages = [...res.data.data].sort((a, b) => {
          const aTime = new Date(a.updatedAt);
          const bTime = new Date(b.updatedAt);
          if (aTime > bTime) {
            return -1;
          }
          if (aTime < bTime) {
            return 1;
          }
          return 0;
        });

        setThreadList(sortedMessages);
      });
  };

  const storeChatHandler = (ques: string, answer: string) => {
    const body: { question: string; answer: string; threadId?: string } = {
      question: ques,
      answer: answer,
    };

    if ((newChat && newThreadId) || selectedThread) {
      body.threadId = newThreadId || selectedThread._id;
    }

    axios
      .post(`${Global.newBaseUrl}/chat`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        let updatedMessages = [];
        setMessages((prevMessages) => {
          updatedMessages = [...prevMessages];
          updatedMessages = updatedMessages.filter(
            (message) => message.type !== "loading"
          );
          updatedMessages.push({ type: "answer", text: res.data.data.answer });
          return updatedMessages;
        });
        setLoading(false);
        getThreadList();
        setSearchValue("");
      });
  };

  const getSelectedThreadChat = (val: any) => {
    axios
      .get(`${Global.newBaseUrl}/thread/detail/${val._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const chatHistory = res.data.data[0].chats;

        const transformedMessages = chatHistory
          .map((chat: any) => [
            { type: "question", text: chat.question },
            { type: "answer", text: chat.answer },
          ])
          .flat();

        setMessages(transformedMessages);
        setSelectedThread(val);
        setTitleChange(val.title);
      })
      .catch((error) => {
        console.error("Error fetching thread details:", error);
      });
  };

  const sendMessageHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchValue) {
      const newQuestion = searchValue;
      let updatedMessages = [];
      const uuid: string = Global.generateUUID();
      setMessages([...messages, { type: "question", text: newQuestion }]);
      setLoading(true);

      setMessages((prevMessages) => {
        updatedMessages = [...prevMessages];
        updatedMessages.push({ type: "loading", text: "Loading..." });
        return updatedMessages;
      });

      const data: SendQueryParams = {
        messageId: uuid,
        text: searchValue,
        senderId: localStorage.getItem("userId") as string,
        chatBot: `${selectOption}`,
        timestamp: Date.now() / 1000,
      };

      ChatClient.send(data)
        .then((res) => {
          storeChatHandler(searchValue, res.contents.text);
          if (!newChat) {
            getSelectedThreadChat(selectedThread);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleKeyDown = (e: any) => {
    // Check if the Enter key is pressed (keyCode 13)
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault(); // Prevents a new line from being added
      sendMessageHandler(e);
    }
  };

  function scrollToBottom() {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    scrollToBottom();
  }, [selectedThread, threadList, messages]);

  const newChatHandler = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();
    setNewChat(true);
    setMessages([]);
    setNewThreadId("");
    setSelectedThread("");
    isMobile && setToggle(false);
  };

  const chatMessages = messages.map((message, index) => (
    <div key={index} className={`message ${message.type}`}>
      {message.type === "question" ? (
        <div className="user-question">
          <h4>
            <InitialsAvatar
              name={localStorage.getItem("user_traits") as string}
            />
            {message.text}
          </h4>
        </div>
      ) : (
        <div className="bot-answer">
          <h5>
            <SmartToy />{" "}
            {message.type === "loading" ? (
              "Hang on! Val is thinking..."
            ) : (
              <div className="formatted-text markdown-body">
                <ReactMarkdown rehypePlugins={[rehypeSanitize]}>
                  {message.text}
                </ReactMarkdown>
              </div>
            )}
          </h5>
        </div>
      )}
    </div>
  ));

  const onLogout = () => {
    axios
      .get(`${Global.newBaseUrl}/auth/logout`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace("/");
      });
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setSearchValue(event.target.value);
  };

  const name = localStorage.getItem("user_traits") as string;

  return (
    <ThemeProvider theme={theme}>
      <div className="chat-container">
        <div className="mobile-header">
          <IconButton className="showMobile" onClick={(e) => newChatHandler(e)}>
            <AddIcon />
          </IconButton>
        </div>
        <div className={`chat-threads ${toggle ? "" : "off"}`}>
          <div className="header">
            <IconButton
              className={toggle ? "show" : "hide"}
              onClick={(e) => newChatHandler(e)}
            >
              <AddIcon /> New Chat
            </IconButton>

            {toggle ? (
              <IconButton onClick={() => setToggle(false)}>
                <ToggleOn />
              </IconButton>
            ) : (
              <IconButton className="off" onClick={() => setToggle(true)}>
                <ToggleOffIcon />
              </IconButton>
            )}
          </div>
          <Threads
            threadList={threadList}
            getSelectedThreadChat={getSelectedThreadChat}
            setNewChat={setNewChat}
            setToggle={setToggle}
            selectedThread={selectedThread}
            newThreadId={newThreadId}
            getThreadList={getThreadList}
            setMessages={setMessages}
            setNewThreadId={setNewThreadId}
            setSelectedThread={setSelectedThread}
            setTitleChange={setTitleChange}
            titleChange={titleChange}
          />
          <div className="footer-navigation">
            <Button
              id="positioned-button"
              aria-controls={open ? "positioned-button" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <div>
                <InitialsAvatar name={name !== null ? name : ""} />
                <h4>{localStorage.getItem("user_traits")}</h4>
              </div>
              <MoreVertOutlined sx={{ opacity: "0.5" }} />
            </Button>
            <StyledMenu
              id="positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              slotProps={{
                paper: {
                  style: {
                    width: "20ch",
                    background: "#000",
                  },
                },
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <StyledMenuItem className="menu-list" onClick={onLogout}>
                Logout
              </StyledMenuItem>
            </StyledMenu>
          </div>
        </div>
        <div className="chat-screen">
          {newChat && !messages.length && (
            <div className="chatbot-dropdown">
              <FormControl>
                <InputLabel id="label">Choose a chatbot</InputLabel>
                <Select
                  id="label"
                  value={selectOption}
                  onChange={(_e, val) => {
                    setSelectOption(val as unknown as string);
                  }}
                  className="select-bot"
                >
                  <Option value="ValGPT">Val</Option>
                  <Option value="ValGPTdev1">Val2</Option>
                  <Option value="ValGPTdev2">Val3</Option>
                  <Option value="ValGPTdev3">Brokerage</Option>
                  <Option value="ValGPTdev4">Investor</Option>
                  <Option value="ValGPTdev5">Home Care</Option>
                  <Option value="ValGPTdev6">Tracy</Option>
                  <Option value="ValGPTdev7">iBuyer</Option>
                  <Option value="ValGPTdev8">REIT</Option>
                  <Option value="ValGPTdev9">Appraisal</Option>
                </Select>
              </FormControl>
            </div>
          )}
          <div className="chat-messages" ref={scrollRef}>
            {chatMessages}
          </div>
          <div ref={scrollRef}></div>
          <div className="search-input">
            <form onSubmit={sendMessageHandler}>
              <TextareaAutosize
                minRows={1}
                maxRows={6}
                value={searchValue}
                placeholder="Send a message"
                aria-label="Type to send a message"
                onChange={handleTextChange}
                autoFocus
                onKeyDown={handleKeyDown}
              />

              <Button
                disabled={searchValue.length ? false : true}
                type="submit"
              >
                {loading ? (
                  <CircularProgress color="success" />
                ) : (
                  <SendIcon
                    sx={{
                      color: searchValue.length ? "#fff" : "#6b6c7b",
                      background: searchValue.length
                        ? "#19c37d"
                        : "transparent",
                      padding: searchValue.length ? "6px 5px" : null,
                      width: searchValue.length ? "1.5em" : null,
                      height: searchValue.length ? "1.5em" : null,
                      borderRadius: "5px",
                      transition: "0.3s",
                    }}
                  />
                )}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Chat;
