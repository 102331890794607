import {
  ChatBubbleOutline,
  DeleteOutlineOutlined,
  DoneOutlineOutlined,
  EditOutlined,
} from "@mui/icons-material";
import isMobile from "../../../helpers/utils";
import { IconButton, TextField } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import Global from "../../../config/Global";

interface ThreadList {
  _id: string;
  userId: string;
  title: string;
  status: string;
}

export const Threads = (props: any) => {
  const {
    threadList,
    getSelectedThreadChat,
    setNewChat,
    setToggle,
    selectedThread,
    newThreadId,
    getThreadList,
    setNewThreadId,
    setMessages,
    setSelectedThread,
    setTitleChange,
    titleChange,
  } = props;

  const [editTitle, setEditTitle] = useState(false);

  const titleChangeHandler = (id: string) => {
    axios
      .put(
        `${Global.newBaseUrl}/thread`,
        {
          title: titleChange,
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        getThreadList();
        setEditTitle(false);
      });
  };

  const deleteChatHandler = (id: string) => {
    axios
      .delete(`${Global.newBaseUrl}/thread/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        getThreadList();
        setNewChat(true);
        setNewThreadId("");
        setMessages([]);
        setSelectedThread("");
      });
  };

  const titleHandler = (val: any) => {
    return (
      <>
        <div
          onClick={() => {
            if (val._id) {
              getSelectedThreadChat(val);
            }
            setNewChat(false);
          }}
          onTouchEnd={() => {
            if (val._id) {
              getSelectedThreadChat(val);
            }
            setNewChat(false);
          }}
          role="button"
          tabIndex={0}
          className="click-thread"
        >
          <ChatBubbleOutline
            className="chat-bubble"
            style={{ color: "#fff", width: "15px" }}
          />

          {((selectedThread && selectedThread._id === val._id) ||
            newThreadId === val._id) &&
          editTitle ? (
            <TextField
              variant="outlined"
              required
              value={titleChange}
              onChange={(e) => {
                e.stopPropagation();
                setTitleChange(e.target.value);
              }}
              onBlur={(e) => e.target.focus()}
            />
          ) : (
            <p title={val?.title}>{val?.title}</p>
          )}
        </div>
        <div className="title-container">
          {(selectedThread && selectedThread._id === val._id) ||
          newThreadId === val._id ? (
            <div className="buttons">
              {!editTitle ? (
                <IconButton onClick={() => setEditTitle(true)}>
                  <EditOutlined sx={{ color: "#fff" }} />
                </IconButton>
              ) : (
                <IconButton onClick={() => titleChangeHandler(val._id)}>
                  <DoneOutlineOutlined sx={{ color: "#fff" }} />
                </IconButton>
              )}
              <IconButton onClick={() => deleteChatHandler(val._id)}>
                <DeleteOutlineOutlined sx={{ color: "#fff" }} />
              </IconButton>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <div className="thread-list">
      <ul>
        {threadList?.map((val: ThreadList) => {
          return val.status === "active" ? (
            <li
              className={
                (selectedThread && selectedThread._id === val._id) ||
                newThreadId === val._id
                  ? "selected"
                  : ""
              }
              key={val._id}
            >
              {titleHandler(val)}
            </li>
          ) : null;
        })}
      </ul>
    </div>
  );
};
