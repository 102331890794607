import React from "react";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  component: React.ElementType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
}) => {
  if (localStorage.getItem("token")) {
    return <Component />;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
