import Global from "../config/Global"

export type GetHistoryParams = {
  chatBot: string
  userId: string
}

export type SendQueryParams = {
  senderId: string,
  messageId: string,
  text: string,
  chatBot: string,
  timestamp: number
}

export default {

  async get(options: GetHistoryParams): Promise<any> {

    const url = Global.baseURL

    const response = await fetch(`${url}/bots/${options.chatBot}/history`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        limit: 100,
        user_id: options.userId
      })
    })
    const data = await response.json()
    return data
  },

  async send(options: SendQueryParams): Promise<any> {

    const url = Global.baseURL

    const response = await fetch(`${url}/bots/${options.chatBot}/respond`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sender_id: options.senderId,
        recipient_id: options.chatBot,
        message_id: options.messageId,
        contents: {
          text: options.text
        },
        timestamp: options.timestamp
      })
    })
    
    const data = await response.json()
    return data
  },
}