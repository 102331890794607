import { Box, Button } from "@mui/material";
import axios from "axios";

import Global from "../../config/Global";

export const Verification = () => {
  const resendEmailHandler = () => {
    const userMail = localStorage.getItem("email");
    axios.post(`${Global.newBaseUrl}/auth/resend-mail`, {
      email: userMail,
    });
  };

  return (
    <div className="auth">
      <Box sx={{ textAlign: "center" }}>
        <h2>Verify your email</h2>
        <h4>
          We sent an email to {localStorage.getItem("email")}. Click the link
          inside to get started.
        </h4>
        <Button color="success" onClick={resendEmailHandler}>
          Resend email
        </Button>
      </Box>
    </div>
  );
};
