import { Alert, Button, FormGroup, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import "../auth/Auth.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation, useNavigate } from "react-router-dom";
import Global from "../../config/Global";
import isMobile from "../../helpers/utils";

export const Profile = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userToken = location.pathname.split("/")[2];
  const { control, register, handleSubmit, formState } = useForm({
    mode: "all",
  });
  const [error, setError] = useState({ status: false, message: "" });

  useEffect(() => {
    if (userToken) {
      axios.get(`${Global.newBaseUrl}/auth/verify/${userToken}`).then((res) => {
        const data = res.data.data;
        localStorage.setItem("email", data.email);
        localStorage.setItem("token", data.token);
        localStorage.setItem("userId", data._id);
      });
    }
  }, []);

  const onSubmit = (data: any) => {
    profileSetupHandler(data);
  };

  const profileSetupHandler = (data: any) => {
    let body;
    body = {
      firstName: data.firstName,
      lastName: data.lastName,
      dob: data.birthDate,
      phone: data.phone,
    };

    axios
      .put(`${Global.newBaseUrl}/auth/completeProfile`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res: any) => {
        if (res.data.statusCode !== 400) {
          localStorage.setItem(
            "user_traits",
            `${res.data.data.firstName} ${res.data.data.lastName}`
          );

          navigate("/chat");
        } else {
          setError({ status: true, message: res.data.data });
        }
      });
  };

  return (
    <div className="auth">
      <h2>Tell us about you</h2>
      <div className="profile-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup
            sx={{
              flexDirection: "row",
              gap: "10px",
              flexWrap: isMobile ? "wrap" : "nowrap",
            }}
          >
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  id="firstName"
                  label="First Name"
                  error={!!formState.errors.firstName}
                  helperText={
                    formState.errors?.firstName ? (
                      <>{formState.errors?.firstName?.message}</>
                    ) : (
                      ""
                    )
                  }
                  {...field}
                  {...register("firstName", {
                    required: "Please Enter first name",
                    minLength: {
                      value: 3,
                      message: "Minimun name length 3",
                    },
                  })}
                />
              )}
            />

            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  id="lastName"
                  label="Last Name"
                  error={!!formState.errors.lastName}
                  helperText={
                    formState.errors?.lastName ? (
                      <>{formState.errors?.lastName?.message}</>
                    ) : (
                      ""
                    )
                  }
                  {...field}
                  {...register("lastName", {
                    required: "Please Enter last name",
                  })}
                />
              )}
            />
          </FormGroup>
          {/* <FormGroup>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  type="tel"
                  id="phone"
                  label="Phone"
                  error={!!formState.errors.phone}
                  helperText={
                    formState.errors?.phone ? (
                      <>{formState.errors?.phone?.message}</>
                    ) : (
                      ""
                    )
                  }
                  {...field}
                  {...register("phone", {
                    required: "Please Enter Phone Number",
                    maxLength: {
                      value: 12,
                      message: "Maximum 12 digits allowed",
                    },
                    minLength: {
                      value: 10,
                      message: "Minimum 10 digts allowed",
                    },
                  })}
                />
              )}
            />
          </FormGroup> */}
          {/* <FormGroup>
            <Controller
              control={control}
              name="birthDate"
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="DOB"
                    format="MM/DD/YYYY"
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    {...field}
                  />
                </LocalizationProvider>
              )}
            />
          </FormGroup>
          {error.status ? (
            <Alert severity="error">{error.message}</Alert>
          ) : null} */}
          <Button
            sx={{
              marginTop: "20px",
              width: "100%",
              padding: "15px",
              fontSize: "18px",
              textTransform: "capitalize",
            }}
            type="submit"
            variant="contained"
            color="success"
          >
            Continue
          </Button>
        </form>
      </div>
    </div>
  );
};
